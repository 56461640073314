// default app vars

let g_dev = false;
let g_windowHeight = $(window).height();
let g_windowWidth = $(window).width();
let g_scroll = $(window).scrollTop();

// custom vars
let scrollMargin = 20;
let scrollTreshold = 25;

$(window).on('scroll', function () {
    g_scroll = $(window).scrollTop();
});

// On windows.resize
$(window).on('resize', function () {
    // Set window height/width on resize
    g_windowHeight = $(window).height();
    g_windowWidth = $(window).width();
});
